import React, { useState, useEffect } from "react"
import {StaticImage} from "gatsby-plugin-image"
import meTo from '../images/nav-logo-v2.svg'
import email from '../images/newEmail.svg'
import tcn from '../images/tcn.svg'
import bf from '../images/butterflyNew.svg'
import gitH from '../images/newGit.svg'
import music from '../images/newPlaylist.svg'
import border from '../images/bottomBorder.svg'



const IndexPage = () => {

  return (
    <div id="new-home">
      <div className="new-top">
        <div className="left">
      <img src={meTo} alt="Kendall Bendheim, retired Magician"/>
        </div>
        <div className="right">
          <StaticImage src="../images/newMe.png" alt="kendall bendheim"/>
        </div>
      </div>
      <div className="newRow topRow">
        <div className="newItem">
          <a href="mailto:kendall.bendheim@gmail.com" target="_blank">Email</a>
          <a href="mailto:kendall.bendheim@gmail.com" target="_blank">
            <img src={email} alt="email icon"/>
          </a>
        </div>
      </div>
      <div className="newRow middleRow">
      <div className="newItem">
          <a href="https://www.tectonica.co" target="_blank">Tectonica</a>
          <a href="https://www.tectonica.co" target="_blank">
            <img src={tcn} className="tcn" alt="tectonica"/>
          </a>
        </div>
        <div className="newItem butterfly">
            <img src={bf} alt="rainbow butterfly"/>
        </div>
        <div className="newItem">
          <a href="https://github.com/okaykendall" target="_blank">Code</a>
          <a href="https://github.com/okaykendall" target="_blank">
            <img src={gitH} alt="github link"/>
          </a>
        </div>
      </div>
      <div className="newRow bottomRow">
      <div className="newItem">
          <a href="https://open.spotify.com/user/kendall.bendheim?si=0348c03d165f4c3f" target="_blank">Playlists</a>
          <a href="https://open.spotify.com/user/kendall.bendheim?si=0348c03d165f4c3f" target="_blank">
            <img src={music} alt="spotify icon"/>
          </a>
        </div>
      </div>
      <div className="bottomBorder">
        <img src={border} alt=""/>
      </div>
    </div>
  )
}


export default IndexPage

// return (
//   <div id="homeWrapper">
//     <Layout>
//       <Seo title="Home" />
//       <div>
//         <Nav />
//         <div className="home-wrapper">
//           <div className="container">
//             <div className="home-wrapper--inner">
//               <div className="home-wrapper__image">
//                 <GatsbyImage
//                   className="bio-pic"
//                   image={getImage(data.about.mainImage)}
//                 />
//               </div>
//               <div className="home-wrapper__content">
//                 <h2>
//                   Hi! I'm Kendall ❦ an account and project manager at <a href="https://www.tectonica.co" target="_blank">tectonica
//                   </a>, and based loosely between Barcelona and London
//                 </h2>
//                 <p>
//                 formerly a web dev and originally from sweet, sweet Virginia, I've spent the last 5 years working, studying, and eating olives in Barcelona.
//                 </p>
//                 <p>
//           while i still code for fun, in my current position at Tectonica, 
//           I'm helping our clients create change as they build movements in the real world through digital spaces 🌐
//                 </p>
//                 <div className="home-sm">
//                   <a href="mailto:kendall.bendheim@gmail.com" target="_blank">
//                     <img src={mailIcon} alt="email" />
//                   </a>
//                   <a href="https://github.com/okaykendall" target="_blank">
//                     <img src={ghIcon} alt="git hub" />
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* <HomeLoader/> */}
//       {/* <RealHome data={data} /> */}
//     </Layout>
//   </div>
// )

// const IndexPage = ({ data }) => {
//   const [ball, setBall] = useState(true)
//   // Some random colors

//   const handleBalls = () => {
//     if (ball) {
//       // const colors = ["#3CC157", "#2AA7FF", "#1B1B1B", "#FCBC0F", "#F85F36"]
//       const colors = ["#3c6e93", "#f2c163", "#848ec6", "#eeb2ac", "#2f6d4b"]
//       const numBalls = 80
//       const balls = []

//       for (let i = 0; i < numBalls; i++) {
//         const wrapper = document.getElementById("homeWrapper")
//         let ball = document.createElement("div")
//         ball.classList.add("ball")
//         ball.style.background =
//           colors[Math.floor(Math.random() * colors.length)]
//         ball.style.left = `${Math.floor(Math.random() * 100)}vw`
//         ball.style.top = `${Math.floor(Math.random() * 100)}vh`
//         ball.style.transform = `scale(${Math.random()})`
//         ball.style.width = `${Math.random()}em`
//         ball.style.height = ball.style.width

//         balls.push(ball)
//         wrapper.append(ball)
//       }

//       // Keyframes
//       balls.forEach((el, i, ra) => {
//         let to = {
//           x: Math.random() * (i % 2 === 0 ? -11 : 11),
//           y: Math.random() * 12,
//         }

//         let anim = el.animate(
//           [
//             { transform: "translate(0, 0)" },
//             { transform: `translate(${to.x}rem, ${to.y}rem)` },
//           ],
//           {
//             duration: (Math.random() + 1) * 2000, // random duration
//             direction: "alternate",
//             fill: "both",
//             iterations: Infinity,
//             easing: "ease-in-out",
//           }
//         )
//       })
//     } else {
//       const balls = document.querySelectorAll(".ball")

//       balls.forEach(ball => {
//         ball.remove()
//       })
//     }
//   }

//   useEffect(() => {
//     handleBalls()
//   }, [])



// export const query = graphql`
//   query {
//     about: datoCmsAbout {
//       heading
//       mainImage {
//         gatsbyImageData(placeholder: BLURRED)
//       }
//       words
//     }
//   }
// `
